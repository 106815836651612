<template>
    <div>
        <div class="calculation-headers">
            <div class="performance">
                <QuestionBadge :id="'pendo-statement-company-performance'"><h2>COMPANY PERFORMANCE</h2></QuestionBadge>
                <p>This graph illustrates company performance and the size of your projected Potential Award. This value may be modified by other factors detailed in the calculation below.</p>
            </div>
            <div v-if="hasOrganizationUnits" class="allocation">
                <QuestionBadge :id="'pendo-statement-allocation'"><h2>ALLOCATION</h2></QuestionBadge>
                <p>Your award is allocated on the following plan metrics. See Metrics/Performance below for additional information.</p>
            </div>
        </div>

        <plan-card class="plan-calculation">
            <div class="charts">
                <line-chart class="line-chart" v-if="hasHistory" :dates="dates" :tiers="tiers" :history="client.performance.history"/>
                <doughnut-chart class="pie-chart" v-if="hasOrganizationUnits" :client="client" :employee="employee" :organization="organization" :distributions="distributions" />
            </div>
            <plan-distributions
                v-if="hasDistributions"
                :distributions="distributions"
                :organization="organization"
                :employee="employee"
                :plan="plan"
                :client="client"
            />
            <plan-breakdown v-else :client="client" :employee="employee" :organization="organization" :forecast="forecast" />
        </plan-card>
    </div>
</template>

<script>
import PlanCard from "@/components/plans/global/PlanCard.vue";
import DoughnutChart from "@/components/plans/short-term/charts/DoughnutChart.vue";
import PlanBreakdown from "@/components/plans/short-term/calculation/PlanBreakdown.vue";
import LineChart from "@/components/plans/short-term/charts/LineChart.vue";
import QuestionBadge from "@/components/pendo/QuestionBadge.vue";
import PlanDistributions from "@/components/plans/short-term/PlanDistributions.vue";

export default {
    name: "PlanCalculation",

    components: {
      PlanDistributions,
        QuestionBadge,
        LineChart,
        PlanCard,
        DoughnutChart,
        PlanBreakdown,
    },

    props: {
        dates: {
            type: Object,
            required: true,
        },
        tiers: {
            type: Object,
            required: true,
        },
        forecast: {
            type: Object,
            required: true,
        },
        client: {
            type: Object,
            required: true,
        },
        employee: {
            type: Object,
            required:true,
        },
        organization: {
            type: Object,
            required: false,
        },
        distributions: {
            type: Array,
            required: true,
        },
        plan: {
          type: Object,
          required: true,
        },
    },

    computed: {
        hasHistory() {
            return this.client?.performance
        },
        isEos() {
          return this.plan.isEos;
        },
        hasDistributions() {
          return this.plan?.holdback.mode === 'enabled'
        },
        hasOrganizationUnits() {
            return this.organization?.units && Object.values(this.organization?.units).length
        },
    },

    methods: {},

    mounted() {}
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/_variables.scss";

.calculation-headers {
  display: flex;

  margin-bottom: 1.125rem;

  .performance, .allocation {
    p {
      font-size: $text-sm;
    }
  }

  .performance {}

  .allocation {
    min-width: 28%;
    margin-left: 1.25em;
  }
}

.plan-calculation {
  padding: 1.2rem 2.6rem;

  .charts {
    display: flex;

    padding-bottom: 3.6rem;

    border-bottom: 2px solid $cloud;

    .pie-chart {
      min-width: 35%;
      margin-left: 1.25em;
    }
  }
}
</style>