<template>
	<div>
		<h1>Long-Term Plans</h1>

    <template v-if="loading.plan || loading.certificate">
      Loading . . .
    </template>
    <template v-else-if="clientIsDiy && (!loading.certificate && pendingCertificate)">
      <div class="accept-container">
        <div class="accept-pdf-wrapper">
          <PDFViewer class="pdf-viewer" :pdf-url="certificateUrl" :width="viewDimensions.width * 0.8"/>
        </div>

        <p>
          By Accepting this Agreement, the Participant acknowledges having received the Agreement and applicable Award
          Certificate. The Participant also agrees that the Phantom Shares will be governed by the terms of this
          Agreement and the Award Certificate.
        </p>

        <div class="accept-footer">
          <div class="accept-participant-wrapper">
            <div>{{ userData.firstName }} {{ userData.lastName }}</div>
            <div class="accept-participant">Participant Name</div>
          </div>
          <div class="accept-date-wrapper">
            <div>{{ today | date('', 'DIRECT') }}</div>
            <div class="accept-date">Date</div>
          </div>
          <div class="accept-button-wrapper">
            <button @click="onAccept">Acknowledged & Accepted</button>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="!loading.plan && showSummary">
      <LongTermPlanView :plan="this.plan"/>
    </template>
    <template v-else-if="!loading.plan && !showSummary">
      <h3>No Plans Available to View</h3>
    </template>

		<vk-modal v-if="showErrorMessage" :show.sync="showErrorMessage">
			<vk-modal-title>Communication Error</vk-modal-title>
			<div>
				<p>There was an error connecting to the server.</p>
				<p>We were unable to obtain the data for this page at this time. Please try again later.</p>
			</div>
			<vk-modal-close></vk-modal-close>
		</vk-modal>
	</div>
</template>

<script>
import LongTermPlanView from "@/components/pages/LongTermPlanView.vue";
import PDFViewer from "@/components/elements/PDFViewer.vue";
export default {
	name: "LongTerm",

	components:{
    PDFViewer,
    LongTermPlanView,
		// LongPlanBox
	},

	data() {
		return {
      loading: {
        plan: false,
        certificate: false,
      },
      pendingCertificate: null,
      certificateDownloaded: false,

			plan: null,
			showErrorMessage: false,
			loaded: false,

      pdfLink: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/examples/learning/helloworld.pdf',

      viewDimensions: {
        width: null,
        height: null,
      },
		}
	},

	mounted() {
		!this.$dataDirectory.userData.isDemo ?
			this.init():null;

    if(this.clientIsDiy) this.getPendingCertificates()
    this.getSummary()
	},

  computed: {
    plan_id() {
      return this.$route.params.id
    },
    showSummary() {
      return this.plan?.current
    },
    clientIsDiy() {
      return this.$dataDirectory.userData.client_status === 'diy' || this.$dataDirectory.userData.client_status === 'DIY';
    },
    userData() {
      return this.$dataDirectory.userData;
    },
    today() {
      return new Date();
    },
    certificateUrl() {
      let route = this.$apiManager.route(`certificate/download`);
      return `${route}?plan_id=${this.pendingCertificate.plan_id}&input_group_index=${this.pendingCertificate.grant_group_index}&year=${this.pendingCertificate.year}`;
    }
  },

	methods: {
		init(){
			this.$apiManager.get("plans/long-term/0/10").then(
				(pack) => this.plansDataReceived(pack),
				() => this.plansDataFailure()
			);
		},
		plansDataReceived(pack){
			if(pack.data && pack.data.success){
				this.plans = pack.data.plans;
			}
			this.loaded = true;
		},
		plansDataFailure(){
			this.showErrorMessage = true;
		},

    getPendingCertificates() {
      this.loading.certificate = true;
      this.$apiManager.get(`certificate/pending/${this.plan_id}`)
          .then((result) => {
            if(result.data.length) this.pendingCertificate = result.data[0];
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading.certificate = false;
          });
    },

    getSummary() {
      this.loading.plan = true;
      this.$apiManager.get(`plans/ltip/${this.plan_id}`)
        .then((result) => {
          this.plan = result.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loading.plan = false;
        })
    },

    onDownload() {
      this.certificateDownloaded = true;

      window.open(this.certificateUrl, '_blank').focus();
    },

    onAccept() {
      console.log('accept')
    },

    onResize() {
      this.viewDimensions.width = window.innerWidth;
      this.viewDimensions.height = window.innerHeight;
    },
	},

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },

  destroyed() {
    window.removeEventListener('resize', this.onResize);
  }
}
</script>

<style lang="scss" scoped>
button {
  color: #ffffff;
  background-color: #479dea;
  border: none;
  border-radius: 20em;
  font-size: 14px;
  padding: 0.5rem 2.4rem;
  cursor: pointer;
  text-decoration: none;
  font-family: "Lexend Deca", sans-serif;
  letter-spacing: 0.08em;
  font-weight: 500;
  height: 36px;

  &:hover{
    color: #ffffff;
    background: linear-gradient(90deg, #479dea 31.5%, #de97ff);
  }
}

.accept-container {
  display: flex;
  flex-direction: column;

  p {
    font-weight: bold;
    margin-bottom: 1rem;
  }
}

.accept-pdf-wrapper {
  margin-bottom: 1rem;
}

.accept-participant-wrapper {
  flex-grow: 5;
}

.accept-date-wrapper {
  flex-grow: 2
}

.accept-button-wrapper {}

.accept-participant, .accept-date {
  border-top: solid 1px $charcoal;

  font-weight: bold;
}

.accept-footer {
  display: flex;

  >div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:nth-child(2) {
      padding: 0 3rem;
    }
  }
}
</style>
