<template>
	<div class="reset-password auth">
		<terms-form :display-terms="!termsAccepted" @terms-accepted="termsAccepted=true"></terms-form>
		<div class="container">
			<img src="../../assets/visionlink-light.svg" alt="VisionLink" />
			<div  class="card">
				<transition name="fade">
					<div class="reset-password-verify" v-if="termsAccepted">
						<input-field
							v-model="email"
							:editable="false">
							Email
						</input-field>
						<password-field
							field-type="password"
							v-model="password">
							Password
						</password-field>
						<password-field
							field-type="password"
							v-model="verifyPassword">
							Verify Password
						</password-field>
						<standard-button @click="resetPassword">Login</standard-button>
					</div>
				</transition>
			</div>
			<vk-modal v-if="showError" :show.sync="showError">
				<vk-modal-title>Password Reset Failed</vk-modal-title>
				<div>{{ errorMessage }}</div>
				<vk-modal-close></vk-modal-close>
			</vk-modal>
		</div>
	</div>
</template>

<script>
import StandardButton from "@/components/elements/StandardButton";
import InputField from "@/components/elements/InputField";
import PasswordField from "@/components/elements/PasswordField";
import TermsForm from "./TermsForm";
export default {
	name: "FirstLogin",
	components: {
		InputField,
		PasswordField,
		StandardButton,
		TermsForm
	},
	data() {
		return {
			email:null,
			code:null,
			termsAccepted: true,
			username: "",
			password: "",
			verifyPassword: "",
			showError: false,
			errorMessage: "XXX"
		}
	},
	mounted() {
		this.email = this.$route.params.email;
		this.code = this.$route.params.code;
		this.termsAccepted = this.$route.params.accepted == 1;
	},
	methods: {
		resetPassword(){
			if(this.password === this.verifyPassword){
				this.$apiManager.post("reset-password", {
					email: this.email,
					code:this.code,
					password: this.password
				}).then(
					(pack) => this.resetComplete(pack),
					() => this.resetFailed()
				);
			} else {
				this.errorMessage = "Password and password confirmation do not match.";
				this.showError = true;
			}
		},
		resetComplete(pack){
			if(pack.data && pack.data.success){
				this.$apiManager.token = pack.data.token;
				if(pack.data.adminUrl){
					this.$apiManager.adminUrl = pack.data.adminUrl;
				}
				this.$apiManager.get("user").then(
					(pack) => this.userDataSuccess(pack),
					() => this.dataFailure(),
				);
			} else {
				this.resetFailed();
			}
		},
		userDataSuccess(pack){
			if(pack.data && pack.data.success){
				this.$dataDirectory.userData = pack.data;
				this.$router.push("/dashboard");
			} else {
				this.dataFailure();
			}
		},
		resetFailed(){
			this.errorMessage = "There was an issue communicating with the server to set up your account. Please try again later.";
			this.showError = true;
		},
		dataFailure(){
			this.errorMessage = "There was an issue communicating with the server. Please try again later.";
			this.showError = true;
		},
	}
}
</script>
<style lang="scss" scoped>
.reset-password{

	.card{
		max-width: 470px;
		border-radius: 15px;
		text-align: center;
		padding: 40px;
		position: relative;
		background-color: #FFFFFF;
	}

	&-verify{
		width: 500px;
		height: 300px;
		position: fixed;
		top:calc(50vh - 150px);
		left: calc(50vw - 250px);
		-webkit-box-shadow: 0px 0px 8px 2px rgba(46,46,46,0.65);
		box-shadow: 0px 0px 8px 2px rgba(46,46,46,0.65);
		border-radius: 15px;
		text-align: center;
		padding: 50px;
	}
}
.fade-enter-active, .fade-leave-active {
	transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>