<template>
  <plan-card :class="['grid', isEos && payoutCount > 0 ? 'grid-cols-5': 'grid-cols-4']">
    <div v-if="isEos && payoutCount > 0" class="grid__item">
      <section>
        <h3><QuestionBadge :id="'pendo-eos-period-payment'">{{ periodLetter }}{{ payoutCount }} Payment</QuestionBadge></h3>
        <p class="sub-header">Date: {{ periodDate | date }}</p>
        <p class="currency-eos">{{ periodPayment | currency }}</p>
        <h3>Paid To Date</h3>
        <p class="currency-eos">{{ latestPaidToDate | currency }}</p>
      </section>
    </div>
    <div class="grid__item">
      <section>
        <h3 id="overview-forecast-label">{{ forecast.label }}</h3>
        <p v-if="isPercentOfSalary" class="sub-header">{{ forecast.percent | percent(1) }} of Plan <span>{{ salaryTerm }}</span></p>
        <p class="currency">{{ forecast.adjusted | currency }}</p>
        <p v-if="!isFinal" class="info">Your current projected plan award value.</p>
      </section>
    </div>
    <div class="grid__item">
      <section>
        <h3 id="overview-base-label">{{ tiers.base.label }}</h3>
        <p v-if="isPercentOfSalary" class="sub-header">{{ tiers.base.percent | percent(1) }} of Plan <span>{{ salaryTerm }}</span></p>
        <p class="currency">{{ tiers.base.value | currency }}</p>
        <p class="info">Your plan opportunity if minimum company results are achieved.</p>
      </section>
    </div>
    <div class="grid__item">
      <section>
        <h3 id="overview-target-label">{{ tiers.target.label }}</h3>
        <p v-if="isPercentOfSalary" class="sub-header">{{ tiers.target.percent | percent(1) }} of Plan <span>{{ salaryTerm }}</span></p>
        <p class="currency">{{ tiers.target.value | currency }}</p>
        <p class="info">Your plan opportunity assuming budgeted financial results are met.</p>
      </section>
    </div>
    <div class="grid__item">
      <section>
        <h3 id="overview-superior-label">{{ tiers.superior.label }}</h3>
        <p v-if="isPercentOfSalary" class="sub-header">{{ tiers.superior.percent | percent(1) }} of Plan <span>{{ salaryTerm }}</span></p>
        <p class="currency">{{ tiers.superior.value | currency }}</p>
        <p class="info">Your plan opportunity if financial results are exceptional.</p>
      </section>
    </div>
  </plan-card>
</template>

<script>
import PlanCard from "@/components/plans/global/PlanCard.vue";
import QuestionBadge from "@/components/pendo/QuestionBadge.vue";

export default {
  name: "PlanOverview",

  components: {
    QuestionBadge,
    PlanCard,
  },

  props: {
    plan: {
      type: Object,
      required: true,
    },
    distributions: {
      type: Array,
      required: false,
    },
    salaryTerm: {
      type: String,
      default: "Salary"
    },
    tiers: {
      type: Object,
      required: true,
    },
    forecast: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    planMode() {
      return this.plan.opportunity_mode
    },
    isFinal() {
      return this.plan.isFinal
    },
    isPercentOfSalary() {
      return this.planMode === 'as-pct'
    },
    periodLetter() {
      if(this.payoutTotal === 4) return 'Q'
      else if(this.payoutTotal === 3) return 'T'
      else return 'H'
    },
    isEos() {
      return !!this.plan?.isEos;
    },
    payoutCount() {
      if(this?.distributions?.length) return this.distributions.length
      else return 0
    },
    payoutTotal() {
      if(this.plan?.holdback?.payout_count) return this.plan.holdback.payout_count
      else return 0
    },
    periodPayment() {
      return this.distributions[this.distributions.length - 1].award_amount
    },
    latestPaidToDate() {
      return this.distributions[this.distributions.length - 1].ytd_bonus
    },
    periodDate() {
      return this.distributions[this.distributions.length - 1].date
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/_variables.scss";

.plan-card {
  min-height: 140px;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  h3, p {
    text-align: center;
  }
}

h3 {
  font-size: $text-base;
  font-weight: bold;

  text-transform: capitalize;
}

.sub-header {
  font-size: $text-sm;

  span {
    text-transform: capitalize;
  }
}

.currency {
  font-family: $font-secondary;
  font-size: $text-xl;
  font-weight: 800;
  color: var(--highlight-color);

  &-eos {
    font-size: $text-lg;
    font-family: $font-secondary;
    font-weight: 800;
    color: var(--highlight-color);
  }

  &-eos:first-letter,
  &:first-letter {
    vertical-align: super;
    font-size: $text-sm;
    letter-spacing: 4px;
  }
}

.info {
  font-size: $text-xs;
}

.grid {
  --gap: 2rem;
  --line-offset: calc(var(--gap) / 2);
  --line-thickness: 1px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  overflow: hidden;
  gap: var(--gap);
}
.grid__item {
  position: relative;
}
.grid__item::before {
  content: '';
  position: absolute;
  background-color: $charcoal-tint-20;
  z-index: 1;
}
.grid__item::before {
  inline-size: var(--line-thickness);
  block-size: 100vh;
  inset-inline-start: calc(var(--line-offset) * -1);
}

.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-cols-5 {
  grid-template-columns: repeat(5, 1fr);
}
</style>